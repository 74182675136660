<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="true")
		form(@submit.prevent="submit")
			.widget
				.widget-header
					h1.title {{ user.id ? `Edit user (${user.id})` : 'Create user' }}
				.widget-body
					div(style="opacity: 0;width: 0; height: 0; overflow: hidden;")
						input(type="text", name="email")
						input(type="password", name="password")
					h4.widget-section-title Account
					.row
						//.col-sm-4(v-if="user.id")
							.form-group
								label ID
								input.form-control(type="text", disabled="disabled", :value="user.id")
						.col-sm-4
							.form-group
								label Email
								input.form-control(type="email", v-model="newEmail")
								small.form-text.text-muted(v-if="user.id") Current email is "{{ user.email }}". Leave blank to keep current value
						.col-sm-4
							.form-group
								label Password
								input.form-control(type="password", v-model="newPassword")
								small.form-text.text-muted(v-if="user.id") Leave blank to keep current value
						.col-sm-4
							.form-group
								label Status
								select.form-control(v-model.number="user.status")
									option(:value="false") Inactive
									option(:value="true") Active
					//h4.widget-section-title Account
					.row
						.col-sm-4
							.form-group
								label Name
								input.form-control(type="text", v-model="user.name")
						.col-sm-4
							.form-group
								label Type
								select.form-control(v-model="user.type")
									option(v-for="o in typeOptions", :value="o.v") {{ o.t }}

						//.col-sm-4
							.form-group
								label &nbsp;
								b-form-checkbox(v-model="user.fun_mode") Fun mode
					.row
						.col-sm-4(v-if="user.type === 'publisher'")
							select-publisher(v-model="userPublisher")


			.row
				//.col-sm-6
					.widget
						.widget-header
							h1.title Preset
						.widget-body
							h4.widget-section-title Advertisers
							div.row
								.col-sm-4(v-for="a in advertiserOptions")
									.form-text
										b-form-checkbox(v-model="selectedAdvertisers[a.v]") {{ a.t }}
							h4.widget-section-title Publishers
							div.row
								.col-sm-4(v-for="a in publisherOptions")
									.form-text
										b-form-checkbox(v-model="selectedPublishers[a.v]") {{ a.t }}

				//.col-sm-12
					.widget(v-if="user.type !== 'admin' && user.permissions")
						.widget-header
							h1.title Permissions
						.widget-body
							.accordion(role="tablist")
								b-card.mb-1(no-body, v-for="g in permissionGroups")
									b-card-header.p-1(header-tag="header", role="tab")
										b-button(block, :v-b-toggle="'accordion-'+g.name", variant="secondary") {{ g.label }}
									b-collapse(:id="'accordion-'+g.name", visible, :accordion="'accordion-'+g.name", role="tabpanel")
										b-card-body
											//b-card-text I start opened because
											//h4
												input(type="checkbox", v-model="user.permissions[g.name]")
												| &nbsp; {{ g.label }}
											div.permission-item(v-for="p in g.permissions")
												label
													input(type="checkbox", v-model="user.permissions[g.name+'::'+p.key]")
													span.title {{ p.title }}
												div.desc {{ p.description }}

				.col-sm-12
					.widget(v-if="user.type !== 'admin' && user.permissions")
						.widget-header
							h1.title Permissions
						.widget-body
							.permission-group(v-for="g in permissionGroups")
								h4
									input(type="checkbox", v-model="user.permissions[g.name]")
									| &nbsp; {{ g.label }}
								div.permission-item(v-for="p in g.permissions")
									label
										input(type="checkbox", v-model="user.permissions[g.name+'::'+p.key]")
										span.title {{ p.title }}
									div.desc {{ p.description }}

			.buttons.text-right
				router-link.btn.btn-default(:to="{name:'user-list'}")
					i.la.la-angle-left
					| Back
				| &nbsp;
				button.btn.btn-primary(type="submit") Save
					i.la.la-fw.la-save
</template>
<style lang="scss">
.permission-group {
  background: #fafafa;
  padding: 25px;
  border-radius: 15px;
  margin-bottom: 30px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  h4 {
    font-size: 14px;
    margin-bottom: 20px;
    input {
    }
  }
  .permission-item {
    padding: 10px 25px 10px 25px;
    margin-bottom: 15px;
    border-left: 3px solid #eee;
    background: #f6f6f6;
    label {
      margin-bottom: 0;
      span.title {
        padding-left: 10px;
        font-weight: 500;
      }
    }
    .desc {
      padding-left: 23px;
    }
  }
}
</style>
<script>
import Vue from 'vue';

export default {
  name: 'UserForm',
  async beforeRouteEnter(to, from, next) {
    let adminPermissionGroups = await Vue.ovData.user.getPermissionGroups();
    let partnerPermissionGroups = await Vue.ovData.user.getPartnerPermissionGroups();
    let advertiserOptions = await Vue.ovData.advertiser.getOptions(true);
    let publisherOptions = await Vue.ovData.publisher.getOptions(true);

    let permStructureAdmin = await Vue.ovData.user.getPermissionStructure();
    let permStructurePartner = await Vue.ovData.user.getPartnerPermissionStructure();
    let user = {};
    if (to.name === 'user-edit') {
      let entityId = +to.params.id;
      try {
        user = await Vue.ovData.user.get(entityId, true);
      } catch (e) {
        Vue.ovNotify.error('Failed to get user');
        return next(false);
      }
    } else {
      user = Vue.ovData.user.newInstance();
      user.permissions = {};
      let pubId = +to.query.publisher_id;
      if (pubId) {
        user.type = 'publisher';
        user.publisher_id = pubId;
      }
    }
    let userPublisher = null;
    if (user.publisher_id) {
      for (let i = 0; !userPublisher && i < publisherOptions.length; i++) {
        if (publisherOptions[i].v === user.publisher_id) {
          userPublisher = publisherOptions[i];
        }
      }
    }

    next((vm) => {
      // vm.permissionGroups = permissionGroups;
      vm.userPublisher = userPublisher ? [userPublisher] : [];
      vm.adminPermissionGroups = adminPermissionGroups;
      vm.partnerPermissionGroups = partnerPermissionGroups;
      vm.publisherOptions = publisherOptions;
      vm.advertiserOptions = advertiserOptions;
      vm.user = user;
      vm.permStructureAdmin = permStructureAdmin;
      vm.permStructurePartner = permStructurePartner;
      let selectedPublishers = {};
      user.partners_permissions.publishers.forEach((p) => {
        selectedPublishers[p] = true;
      });
      let selectedAdvertisers = {};
      user.partners_permissions.advertisers.forEach((p) => {
        selectedAdvertisers[p] = true;
      });
      vm.selectedPublishers = selectedPublishers;
      vm.selectedAdvertisers = selectedAdvertisers;
      return vm;
    });
  },
  computed: {
    USER() {
      return this.$store.state.user;
    },
    permissionGroups() {
      if (this.user.type === 'publisher' || this.user.type === 'advertiser') {
        let groups = this.partnerPermissionGroups.map((g) => ({
          name: g.name,
          label: g.label,
          permissions: g.permissions.filter(
            (o) => !o.user_types || o.user_types.includes(this.user.type),
          ),
        }));
        return groups.filter((g) => g.permissions.length > 0);
      } else {
        return this.adminPermissionGroups;
      }
    },
  },
  data() {
    return {
      busy: false,
      datepickerConf: {
        format: 'DD/MM/YYYY',
      },
      newEmail: '',
      newPassword: '',
      user: {},
      permStructureAdmin: {},
      permStructurePartner: {},
      adminPermissionGroups: [],
      partnerPermissionGroups: [],
      advertiserOptions: [],
      publisherOptions: [],
      selectedAdvertisers: {},
      selectedPublishers: {},
      userPublisher: [],
      typeOptions: [
        // {v: 'mb', t: 'Media Buyer'},
        { v: 'am', t: 'Account Manager' },
        { v: 'admin', t: 'Administrator' },
        { v: 'publisher', t: 'Publisher' },
        { v: 'advertiser', t: 'Advertiser' },
      ],
    };
  },
  methods: {
    async submit() {
      if (this.busy) {
        return;
      }
      this.busy = true;
      let data = Vue.util.extend({}, this.user);
      data.email = this.newEmail;
      data.password = this.newPassword;
      Vue.ovData.user._booleans.forEach((f) => {
        data[f] = data[f] ? 1 : 0;
      });
      let partnersPermissions = {
        advertisers: [],
        publishers: [],
      };
      for (let k in this.selectedAdvertisers) {
        if (this.selectedAdvertisers[k]) {
          partnersPermissions.advertisers.push(k);
        }
      }
      for (let k in this.selectedPublishers) {
        if (this.selectedPublishers[k]) {
          partnersPermissions.publishers.push(k);
        }
      }
      data.partners_permissions = partnersPermissions;

      if (this.userPublisher && this.userPublisher.length) {
        data.publisher_id = this.userPublisher[0].v;
      } else {
        data.publisher_id = null;
      }
      try {
        /*let resp = */ await this.$ovReq.post('user/save', data);
        this.busy = false;
        this.$ovNotify.success('User has been saved');
        this.$router.push({ name: 'user-list' });
      } catch (e) {
        this.$ovNotify.error(e);
        this.busy = false;
      }
    },
  },
};
</script>
